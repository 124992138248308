<template>
    <div>
        <v-card v-if="showWarning" outlined class="red">
            <v-card-text class="white--text">
                {{ warningMessage }}
            </v-card-text>
        </v-card>

        <base-form
            ref="form"
            v-model="form"
            :api="api"
            :mapper="mapper"
            :entity-id="entityId"
            :title="title"
            :disabled="disabled"
            :show-progress="showProgress"
            @created="created"
            @updated="updated"
            @cancelled="cancelled"
            @deleted="deleted"
        >
            <form-panel>
                <form-two-col-row>
                    <template v-slot:col1>
                        <h3 class="headline pt-3 pb-6 black--text">
                            {{ $t('labels.canteen_order_settings_title') }}
                        </h3>

                        <text-input
                            v-model="form.name"
                            v-validate="'required|max:255|space'"
                            name="name"
                            label="labels.name"
                            :error-messages="errors.collect('name')"
                            :data-vv-as="$t('labels.name')"
                        ></text-input>

                        <div class="py-4">
                            <entity-select-input
                                v-model="form.addresses"
                                v-validate="'required'"
                                label="labels.addresses"
                                type="checkbox"
                                :data-vv-as="$t('labels.addresses')"
                                :entity="selectApiClientType.COMPANY_ADDRESS"
                                :query-params="{ company: form.company }"
                                multi-select
                                name="addresses"
                                :error-messages="errors.collect('addresses')"
                            ></entity-select-input>
                        </div>

                        <dates-common-form-part v-model="form">
                        </dates-common-form-part>

                        <h3 class="headline py-6 black--text">
                            {{ $t('labels.canteen_occurences_title') }}
                        </h3>

                        <recur-common-form-part
                            v-model="form"
                        ></recur-common-form-part>
                    </template>
                    <template v-slot:col2>
                        <canteen-restaurant-form-part
                            v-model="form"
                            type="CloudcanteenRestaurant"
                            :restaurant-entity="selectApiClientType.RESTAURANT"
                            :restaurant-query-param="{
                                ccCompanyPartner: form.company,
                            }"
                        ></canteen-restaurant-form-part>

                        <text-input
                            v-model="form.pickupOffset"
                            v-validate="'required|numeric'"
                            type="number"
                            name="pickupOffset"
                            label="labels.default_pickup_offset"
                            :suffix="$t('labels.minutes')"
                            :error-messages="errors.collect('pickupOffset')"
                            :data-vv-as="$t('labels.default_pickup_offset')"
                        ></text-input>

                        <h3 class="headline py-6 black--text">
                            {{ $t('labels.cloudcanteen_budget_title') }}
                        </h3>

                        <currency-input
                            v-model="form.budgetLimit"
                            v-validate="'required|min_value:0|decimal:2'"
                            name="budgetLimit"
                            label="labels.budget"
                            :error-messages="errors.collect('budgetLimit')"
                            :data-vv-as="$t('labels.budget')"
                        ></currency-input>

                        <div class="py-4">
                            <checkbox-input
                                v-model="form.canGroupOrder"
                                name="canGroupOrder"
                                label="labels.can-group-order"
                            ></checkbox-input>
                        </div>

                        <div class="d-flex mx-n3 mt-4 justify-space-between">
                            <div class="pa-3">
                                <h3>
                                    {{ $t('labels.cc_budget_overrides') }}
                                </h3>
                            </div>
                            <div class="pa-3">
                                <add-budget-override
                                    :base-limit="form.budgetLimit"
                                    @added="addBudgetOverride"
                                ></add-budget-override>
                            </div>
                        </div>

                        <v-row
                            v-for="(override, index) in form.budgetOverrides"
                            :key="override['@id']"
                            class="mb-n4"
                        >
                            <v-col cols="6" class="pb-0 pt-4">
                                <entity-select-input
                                    v-model="override.user"
                                    v-validate="'required'"
                                    :name="`overrideUser[${override['@id']}]`"
                                    label="labels.employee"
                                    :entity="selectApiClientType.USER"
                                    :query-params="{
                                        company: companyApiClient.getItemIri(
                                            companyId
                                        ),
                                    }"
                                    :items-filter="
                                        overrideEmployeesFilter(override)
                                    "
                                    :needs-update="overrideSelectUpdate"
                                    autocomplete
                                    :data-vv-as="$t('labels.employee')"
                                    :error-messages="
                                        errors.collect(
                                            `overrideUser[${override['@id']}]`
                                        )
                                    "
                                    @input="updateOverrideSelect()"
                                ></entity-select-input>
                            </v-col>

                            <v-col cols="4" class="py-0">
                                <currency-input
                                    v-model="override.budgetLimit"
                                    v-validate="'required|decimal:2'"
                                    :name="`overrideBudgetLimit[${override['@id']}]`"
                                    label="labels.budget"
                                    type="number"
                                    :data-vv-as="$t('labels.budget')"
                                    :error-messages="
                                        errors.collect(
                                            `overrideBudgetLimit[${override['@id']}]`
                                        )
                                    "
                                ></currency-input>
                            </v-col>

                            <v-col cols="2" class="pb-0 pt-3">
                                <v-btn
                                    icon
                                    color="error"
                                    @click="removeBudgetOverride(index)"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>

                        <div class="pb-4"></div>

                        <h3 class="headline py-6 black--text">
                            {{ $t('labels.teams_title') }}
                        </h3>

                        <entity-select-input
                            v-model="form.companyTeams"
                            v-validate=""
                            :entity="selectApiClientType.COMPANY_TEAMS"
                            :query-params="{
                                companyId,
                            }"
                            multi-select
                            label="labels.teams"
                            name="teams"
                            :error-messages="errors.collect('teams')"
                            :data-vv-as="$t('labels.teams')"
                        ></entity-select-input>

                        <h3 class="headline py-6 black--text">
                            {{ $t('labels.expense_code_settings_title') }}
                        </h3>

                        <v-row>
                            <v-col cols="6">
                                <select-input
                                    v-model="form.expenseCodeRequired"
                                    name="expenseCodeRequired"
                                    :items="expenseCodeRequired"
                                    label="labels.expense_code_settings"
                                ></select-input>
                            </v-col>
                            <v-col cols="6">
                                <checkbox-input
                                    v-model="form.expenseCodeOnlyFromList"
                                    label="texts.employee_can_only_input_expense_code_from_list"
                                    name="timeSettingsDontApplyToNonCompanyAddresses"
                                ></checkbox-input>
                            </v-col>
                        </v-row>
                        <tag-input
                            v-model="form.expenseCodeList"
                            label="labels.expense_code_list"
                        ></tag-input>
                        <p class="font-italic">
                            {{ $t('labels.combobox_hint') }}
                        </p>

                        <h3 class="headline py-6 black--text">
                            {{ $t('labels.canteen_notifications_title') }}
                        </h3>

                        <div class="pb-6">
                            <checkbox-input
                                v-model="form.sendNotificationWhenOpen"
                                name="sendNotificationWhenOpen"
                                label="labels.send_notification_when_open"
                            ></checkbox-input>
                        </div>

                        <text-input
                            v-model="form.sendNotificationBeforeCutoff"
                            v-validate="'required|numeric'"
                            type="number"
                            name="sendNotificationBeforeCutoff"
                            label="labels.send_notification_before_cutoff"
                            :suffix="$t('labels.minutes')"
                            :error-messages="
                                errors.collect('sendNotificationBeforeCutoff')
                            "
                            :data-vv-as="
                                $t('labels.send_notification_before_cutoff')
                            "
                        ></text-input>
                        <div class="g-b2--text mt-n3">
                            {{ notificationTime || '-' }}
                        </div>
                    </template>
                </form-two-col-row>
            </form-panel>
        </base-form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import routeType from '@/router/routeType'
import ApiClientType from '@/api/RestApiType'
import configType from '@/store/type/configType'
import MapperType from '@/services/mapper/MapperType'
import RestApiCollection from '@/api/RestApiCollection'
import FormBase from '@/components/mixins/FormBase'
import CanteenEditDisableMixin from '@/components/mixins/CanteenEditDisableMixin'
import FormPanel from '@/components/form/FormPanel'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import DatesCommonFormPart from '@/components/pages/canteen/DatesCommonFormPart'
import CanteenRestaurantFormPart from '@/components/pages/canteen/CanteenRestaurantFormPart'
import RecurCommonFormPart from '@/components/pages/canteen/RecurCommonFormPart'
import TextInput from '@/components/form/Inputs/TextInput'
import CurrencyInput from '@/components/form/Inputs/CurrencyInput'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'
import AddBudgetOverride from './AddBudgetOverride'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import SelectInput from '@/components/form/Inputs/SelectInput'
import TagInput from '@/components/form/Inputs/TagInput'

export default {
    components: {
        FormPanel,
        FormTwoColRow,
        DatesCommonFormPart,
        CanteenRestaurantFormPart,
        RecurCommonFormPart,
        TextInput,
        CurrencyInput,
        EntitySelectInput,
        CheckboxInput,
        AddBudgetOverride,
        SelectInput,
        TagInput,
    },
    mixins: [FormBase, CanteenEditDisableMixin],
    props: {
        companyId: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            api: ApiClientType.COMPANY_CLOUDCANTEENS,
            mapper: MapperType.COMPANY_CLOUDCANTEENS_FORM,
            form: {
                name: null,
                openFrom: 12,
                cutoffTime: undefined,
                deliveryTime: undefined,
                budgetLimit: null,
                relatedRestaurants: [],
                addresses: [],
                pickupOffset: 15,
                canGroupOrder: false,
                isRecurring: false,
                recurringWeeks: 1,
                budgetOverrides: [],
                sendNotificationBeforeCutoff: null,
                sendNotificationWhenOpen: false,
                companyTeams: [],
            },
            entity: 'labels.company_cloudcanteens',
            editRoute: routeType.COMPANY_CLOUDCANTEEN_EDIT,
            listRoute: routeType.COMPANY_CLOUDCANTEEN_LIST,
            companyApiClient: RestApiCollection.get(ApiClientType.COMPANIES),
            overrideSelectUpdate: false,
        }
    },
    computed: {
        ...mapGetters({
            currencyCode: configType.getters.CURRENCY_CODE,
            currencyLocale: configType.getters.CURRENCY_LOCALE,
        }),
        expenseCodeRequired() {
            return [
                {
                    title: this.$t('labels.not_required'),
                    value: null,
                },
                {
                    title: this.$t('labels.optional'),
                    value: 1,
                },
                {
                    title: this.$t('labels.required'),
                    value: 2,
                },
            ]
        },
        notificationTime() {
            return this.form.cutoffTime
                ? this.$date(this.form.cutoffTime)
                      .subtract(
                          this.form.sendNotificationBeforeCutoff,
                          'minutes'
                      )
                      .format('YYYY-MM-DD HH:mm')
                : ''
        },
    },
    created() {
        this.form.company = this.companyApiClient.getItemIri(this.companyId)
        this.loadCompanyData()
    },
    methods: {
        editRedirectParams(data) {
            return {
                cloudcanteenId: data.id,
                companyId: this.companyId,
            }
        },
        listRedirectParams() {
            return { companyId: this.companyId }
        },
        updateOverrideSelect() {
            this.overrideSelectUpdate = !this.overrideSelectUpdate
        },
        removeBudgetOverride(index) {
            this.form.budgetOverrides.splice(index, 1)
            this.updateOverrideSelect()
        },
        addBudgetOverride(val) {
            if (
                this.form.budgetOverrides &&
                Array.isArray(this.form.budgetOverrides)
            ) {
                this.$set(this.form, 'budgetOverrides', [
                    ...this.form.budgetOverrides,
                    val,
                ])
            } else {
                this.$set(this.form, 'budgetOverrides', [val])
            }
        },
        overrideEmployeesFilter(item) {
            return (values) =>
                values.filter(
                    (value) =>
                        value.key === item.user ||
                        !this.form.budgetOverrides ||
                        this.form.budgetOverrides.length === 0 ||
                        !this.form.budgetOverrides
                            .map((ovr) => ovr.user)
                            .includes(value.key)
                )
        },
        loadCompanyData() {
            this.companyApiClient.get(this.companyId).then((response) => {
                if (this.form.sendNotificationBeforeCutoff === null)
                    this.$set(
                        this.form,
                        'sendNotificationBeforeCutoff',
                        response.defaultCanteenNotificationTime
                    )
            })
        },
    },
}
</script>
